.partner_main_home_micro_sectionOne_gen_bodyy {
  width: 100%;
  background: #fff;
  position: relative;
  min-height: 600px;

  .partner_main_home_micro_sectionOne_gen_bodyy__sub {
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 40px;
    padding-bottom: 40px;
    width: 100%;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    box-sizing: border-box;
    gap: 10px;
    position: relative;
    min-height: 600px;
  }
}

.partner_sectionOne_top_flexxBody {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  gap: 80px;
  box-sizing: border-box;
  position: relative;
}
.partner_sectionOne_top_flexxImg {
  display: flex;
  align-items: center;
  justify-content: center;
}

.partner_sectionOne_top_flexxtext {
  width: calc(100% - 420px);
  color: #0a0c08;
  font-size: 18px;
  font-weight: 400;
  box-sizing: border-box;
  line-height: 20px;
  margin-bottom: 10px;
}

.partner_sectionOne_top_flexxtext span {
  margin-top: 15px;
  display: block;
}
