.main_home_sectionSeven_gen_bodyy {
  width: 100%;
  background: #fff;
  position: relative;
  min-height: 520px;

  .main_home_sectionSeven_gen_bodyy__sub {
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 80px;
    padding-bottom: 40px;
    width: 100%;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    box-sizing: border-box;
    gap: 10px;
    position: relative;
    min-height: 520px;
  }
}

.main_home_sectionSeven_gen_bodyy__substart_hebtn {
  background: #e24f6d;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: 10px;
  padding: 18px 20px;
  border-radius: 40px;
  cursor: pointer;
  transition: 0.3s ease-in-out 0s;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.main_home_sectionSeven_gen_bodyy__substart_hetext {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
}

.main_home_sectionSeven_gen_bodyy__substart_hearrow {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.main_home_sectionSix_gen_headingtext {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  color: #302711;
  font-size: 34px;
  font-weight: 700;
  margin-bottom: 15px;
}

.main_home_sectionSix_gen_body_text {
  color: #302711;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
}

.main_home_sectionSix_gen_dog_section_seven {
  position: absolute;
  left: 50%;
  bottom: -5px;
  transform: translateX(-50%);
}
