.header_gen_bodyy {
  width: 100%;
  background: #efc356;
  .header_gen_bodyy__sub {
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 40px;
    padding-bottom: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    gap: 10px;
  }
}

.mainHeaderOpt_GenMaxScroll {
  width: 100%;
  position: fixed;

  background: rgba(23, 25, 35, 0.2);
  backdrop-filter: blur(30px);
  z-index: 100;
  margin-top: -10px;
}

.mainHeaderOpt_GenScroll {
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;

  background: transparent;
}
.header_gen_bodyy_left_flexx {
  display: flex;
  align-items: center;
  width: 80%;
  gap: 10px;
  box-sizing: border-box;
}

.header_gen_bodyy_left_flexx_logo {
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin-right: 20px;
}

.header_gen_bodyy_left_flexx_each {
  color: #302711;
  font-weight: 400;
  font-size: 16px;
}

/* Underline effect */
.header_gen_bodyy_left_flexx_each::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px; /* Thickness of the underline */
  background-color: #3c3c1a; /* Color of the underline */
  bottom: 0;
  left: 0;
  transition: width 0.3s ease-in-out;
}

.header_gen_bodyy_left_flexx_each:hover::after {
  width: 100%; /* Expands underline on hover */
}

.mainHeaderOpt_GenScroll ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.mainHeaderOpt_GenScroll ul li {
  margin-right: 20px;
}

.mainHeaderOpt_GenScroll ul li a,
.mainHeaderOpt_GenScroll ul li .link {
  text-decoration: none;
  color: #302711;
  font-size: 16px;
  font-weight: 400;
  position: relative;
  display: inline-block;
  padding: 5px 0;
}

.mainHeaderOpt_GenScroll ul li a:hover,
.mainHeaderOpt_GenScroll ul li .link:hover {
  font-weight: 700;
}

/* Underline effect */
.mainHeaderOpt_GenScroll ul li a::after,
.mainHeaderOpt_GenScroll ul li .link::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px; /* Thickness of the underline */
  background-color: #3c3c1a; /* Color of the underline */
  bottom: 0;
  left: 0;
  transition: width 0.3s ease-in-out;
}

/* Expand underline on hover */
.mainHeaderOpt_GenScroll ul li a:hover::after,
.mainHeaderOpt_GenScroll ul li .link:hover::after {
  width: 100%;
}

.header_gen_bodyy__sub ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.header_gen_bodyy__sub ul li {
  margin-right: 20px;
}

.header_gen_bodyy__sub ul li a,
.header_gen_bodyy__sub ul li .link {
  text-decoration: none;
  color: #302711;
  font-size: 16px;
  font-weight: 400;
  position: relative;
  display: inline-block;
  padding: 5px 0;
}

.header_gen_bodyy__sub ul li a:hover,
.header_gen_bodyy__sub ul li .link:hover {
  font-weight: 700;
}

/* Underline effect */
.header_gen_bodyy__sub ul li a::after,
.header_gen_bodyy__sub ul li .link::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px; /* Thickness of the underline */
  background-color: #3c3c1a; /* Color of the underline */
  bottom: 0;
  left: 0;
  transition: width 0.3s ease-in-out;
}

/* Expand underline on hover */
.header_gen_bodyy__sub ul li a:hover::after,
.header_gen_bodyy__sub ul li .link:hover::after {
  width: 100%;
}
.active-link {
  position: relative;
}

.active-link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0px;
  height: 2px;
  width: 100%;
  background-color: #3c3c1a;
  font-weight: 700;
}

/* Button styles */
.auth-buttons {
  display: flex;
  gap: 10px;
}

.login-btn,
.register-btn {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

.login-btn {
  background-color: #302711;
  color: #fff;
  border-radius: 8px;
  transition: 0.3s ease-in-out 0s;
}

.login-btn:hover,
.register-btn:hover {
  transform: scale(1.05);
}

.register-btn {
  background-color: #e24f6d;
  color: #fff;
  border-radius: 8px;
  transition: 0.3s ease-in-out 0s;
}
