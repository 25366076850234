.main_home_sectionTwo_gen_bodyy {
  width: 100%;
  background: #fcf3dd;
  position: relative;

  .main_home_sectionTwo_gen_bodyy__sub {
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 40px;
    padding-bottom: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    box-sizing: border-box;
    gap: 20px;
    position: relative;
  }
}

.section_two_flex_left_search_body input {
  color: #2d3748;
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding-right: 80px;
  padding-left: 20px;
  outline: none;

  box-sizing: border-box;

  border: 0.5px solid #efc356;
  border-radius: 16px;
  background: #fff;
}

.section_two_flex_left_search_body input::placeholder {
  color: #a89e95;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.section_two_flex_left_search_icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 20px;
  top: 50%;
  /* left: 50%; */
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.section_two_flex_left_search_body {
  /* width: 50px; */
  height: 80px;
  position: relative;
  width: 80%;
}

.main_home_sectionTwo_gen_bodyy_look_upPet {
  color: #302711;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
}

.main_home_sectionTwo_gen_bodyy_look_uppetext {
  color: #302711;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}
