.auth {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: var(--base-color);
  .inputWrapauth {
    width: 500px;
    height: 400px;
    background-color: white;
    border-radius: 5px;
    filter: drop-shadow(1px 1px 1px rgba(128, 128, 128, 0.205));
    padding-left: 40px;
    padding-right: 40px;
    .logoAuth {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding-top: 35px;
      img {
        width: 200px;
      }
    }

    form {
      width: 100%;
      margin-top: 40px;
      .inputWrapHo {
        width: 100%;
        margin-bottom: 20px;
        label {
          font-size: 17px;
          color: #5c5c5c;
        }
        input {
          width: 100%;
          height: 40px;
          margin-top: 6px;
          border: 1px solid #5c5c5c;
          border-radius: 4px;
          padding-left: 10px;
        }
      }
    }

    .btnAuth {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      button {
        height: 40px;
        width: 100%;
        background: #4169e2;
        border-radius: 5px;
        color: white;
      }
    }
  }
}
