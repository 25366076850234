.partner_secTwo_genFlexx_body {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 300px;
}

.partner_secTwo_genFlexx_left {
  width: 30%;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 100px;
  flex-direction: column;
}

.partner_secTwo_genFlexx_left_vet {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  cursor: pointer;
}

.partner_secTwo_genFlexx_left_vet_text {
  color: #302711;
  font-size: 30px;
  font-weight: 400;
}

.partner_secTwo_genFlexx_left_vet_icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.partner_section_two_bunny {
  position: absolute;
  left: 0;
  bottom: -4px;
}
