.petForSale {
  .petForSaleHeader {
    padding-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h2 {
      padding: 0;
      margin: 0;
      text-align: center;
      color: #302711;
      font-size: 22px;
    }
    .petBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #efc356;
      border-radius: 5px;
      padding: 7px 30px 7px 30px;
      cursor: pointer;

      p {
        padding: 0;
        margin: 0;
        text-align: center;
        color: #302711;
        font-size: 15px;
      }
    }
  }

  .petForSaleWrap {
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
    row-gap: 15px;
    margin-top: 40px;
    .petForSaleCon {
      width: 200px;
      height: 200px;
      border: 1px solid #f4d78e;
      border-radius: 5px;
      padding: 10px;
      .petActions {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;

        svg {
          margin-left: 10px;
          color: #efc356;
          font-size: 16px;
          cursor: pointer;
        }
      }
      .petImageWrap {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
        .petImage {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 70px;
          height: 70px;
          border-radius: 50%;
          background-color: #f4d78e58;
          svg,
          img {
            width: 75%;
            height: 75%;
          }
        }
      }
      .petName {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        h2 {
          font-size: 13px;
          margin-bottom: 20px;
          margin-top: 20px;
        }
        button {
          background: #e24f6d;
          padding: 10px 20px 7px 20px;
          width: fit-content;
          height: fit-content;
          font-size: 12px;
          color: #ffffff;
        }
      }
    }
  }
}
