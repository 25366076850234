.section_three_micro_pet_head {
  color: #302711;
  font-size: 44px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 20px;
  margin-top: 100px;
}

.section_three_micro_pet_microotext {
  color: #302711;
  font-weight: 400;
  font-size: 30px;
  text-align: left;
  margin-bottom: 20px;
}

.section_three_flex_left_search_body_microchip input {
  color: #2d3748;
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding-right: 80px;
  padding-left: 20px;
  outline: none;

  box-sizing: border-box;

  border: 1px solid #efc356;
  border-radius: 16px;
  background: #fff;
}

.section_three_flex_left_search_body_microchip input::placeholder {
  color: #a89e95;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.section_three_flex_left_search_icon_microchip {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 20px;
  top: 50%;
  /* left: 50%; */
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.section_three_flex_left_search_body_microchip {
  /* width: 50px; */
  height: 80px;
  position: relative;
  width: calc(100% - 200px);
}

.micro_sectionThree_top_flexx_left_search__ {
  display: flex;
  align-items: center;
  gap: 25px;
  margin-top: 90px;
  //   margin-left: 100px;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 80px;
}

.section_three_flex_left_search___ {
  background: #e24f6d;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding-left: 30px;
  padding-right: 30px;
  gap: 10px;
  height: 80px;
}

.section_three_flex_left_search___img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.section_three_flex_left_search___text {
  display: flex;
  align-items: center;
  justify-content: center;
}
