:root {
  --base-color: #f8faff;
  --gold1: #efc356;
  --white: white;
  --grey1: #efc356;
  --grey2: #efc356;
  --grey3: #efc356;
  --grey4: #efc356;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  /* height: ; */
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: generalFont;
  src: url(./assets/fonts/Josefin_Sans/static/JosefinSans-Regular.ttf);
}

@font-face {
  font-family: BoldFont;
  src: url(./assets/fonts/Josefin_Sans/static/JosefinSans-Bold.ttf);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box !important;
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
  /* position: relative;
  z-index: 1; */
  font-family: generalFont;
}

::-webkit-scrollbar {
  width: 0;
  opacity: 0;
}

.app-padding {
  padding-left: 50px;
  padding-right: 50px;
}

ul {
  list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: BoldFont;
}

button {
  cursor: pointer;
  border: none;
}

input,
select {
  outline: none;
  /* padding-left: 0px; */
}

@media (min-width: 1440px) {
  /* hi-res laptops and desktops */
  .app-padding {
    margin-right: auto;
    margin-left: auto;
    width: 1440px;
  }
}

@media (max-width: 600px) {
  .app-padding {
    padding-left: 20px;
    padding-right: 20px;
  }
}

table td {
  color: #5c5c5c !important;
  font-size: 13px !important;
  white-space: nowrap !important;
}

table th {
  color: #efc356 !important;
  font-size: 13px !important;
  white-space: nowrap;
  font-weight: 600 !important;
}

select,
input {
  border: 1px solid #5c5c5c;
  border-radius: 4px;
  padding-left: 10px;
  color: #5c5c5c;
  font-size: 12px;
}
button {
  height: 40px;
  background: #efc356;
  border-radius: 5px;
  padding: 10px;
  color: #302711;
  font-size: 20px;
}

.pageHeaderCentered {
  display: flex;
  // align-items: center;
  justify-content: center;
  width: 100%;
  h2 {
    font-size: 36px;
    font-weight: 700;
    color: #302711;
  }
}

.generalFormContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  .generalForm {
    display: flex;
    justify-content: space-between;
    min-width: 1200px;
    flex-wrap: wrap;
    .generalFormChild {
      width: 48%;
      margin-bottom: 30px;
    }
  }
}

// @media (max-width: 1526px) {
//   .generalFormContainer {
//     .generalForm {
//       min-width: 100%;
//       .generalFormChild {
//         width: 48%;
//       }
//     }
//   }
// }

// @media (max-width: 900px) {
//   .generalFormContainer {
//     .generalForm {
//       min-width: 100%;
//       flex-direction: column;
//       .generalFormChild {
//         width: 100%;
//         margin-bottom: 20px;
//       }
//     }
//   }
// }
