.partner_vet_clinic_gen_body {
  border: 0.5px solid #e24f6d;
  width: 70%;
  padding: 60px;
  box-sizing: border-box;
  box-shadow: 4px 8px 12px 0px #42171f40;
  min-height: 700px;
  border-radius: 12px;
}

.partner_vet_clinic_gen_body_head {
  color: #e24f6d;
  font-size: 22px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 70px;
}

.partner_vet_clinic_gen_body_text {
  color: #000000;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 20px;
  text-align: left;
}
