.main_home_sectionThree_bodyGen {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 300px;
  gap: 30px;
}

.main_home_sectionThree_bodyIconGen {
  width: 100%;
  height: 300px;
  position: relative;
}

.main_home_sectionThree_bodyIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.main_home_sectionThree_bodyIcon_te {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 170px;
  color: #ffffff;
  font-size: 22px;
  font-weight: 700;
  text-align: center;
}

.main_home_sectionThree_bodyIcon_textt {
  color: #0a0c08;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  line-height: 24px;
  width: 80%;
}
