.main_home_sectionSix_gen_bodyy {
  width: 100%;
  background: #efc356;
  position: relative;
  //   min-height: 1000px;

  .main_home_sectionSix_gen_bodyy__sub {
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 80px;
    padding-bottom: 40px;
    width: 100%;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    box-sizing: border-box;
    gap: 10px;
    position: relative;
    // min-height: 1000px;
  }
}

.main_home_sectionSix_flexed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.main_home_sectionSix_flexed_img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main_home_sectionSix_gen_reg_btn {
  margin-top: 50px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  background: #302711;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 18px 20px;
  border-radius: 40px;
  cursor: pointer;
  transition: 0.3s ease-in-out 0s;
}

.main_home_sectionSix_gen_reg_btn_text {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  transition: 0.3s ease-in-out 0s;
}

.main_home_sectionSix_gen_reg_btn_arrowright {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-in-out 0s;
}
