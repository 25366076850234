.main_home_micro_sectionOne_gen_bodyy {
  width: 100%;
  background: #fff;
  position: relative;
  min-height: 850px;

  .main_home_micro_sectionOne_gen_bodyy__sub {
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 40px;
    padding-bottom: 40px;
    width: 100%;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    box-sizing: border-box;
    gap: 10px;
    position: relative;
    min-height: 850px;
  }
}

.micro_sectionOne_top_flexxBody {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  gap: 80px;
  box-sizing: border-box;
  position: relative;
}

.micro_sectionOne_top_flexxImg {
  display: flex;
  align-items: center;
  justify-content: center;
}

.micro_sectionOne_top_flexxtext {
  width: calc(100% - 420px);
  color: #0a0c08;
  font-size: 18px;
  font-weight: 400;
  box-sizing: border-box;
  line-height: 20px;
  margin-bottom: 70px;
}

.micro_sectionOne_top_flexxtext span {
  margin-top: 15px;
  display: block;
}

.micro_sectionOne_top_flexxAbsolute {
  font-size: 60px;
  font-weight: 700;
  color: #302711;
  position: absolute;
  left: 230px;
  top: 40%;
  transform: translateY(-50%);
}

.section_two_flex_left_search_body_microchip input {
  color: #2d3748;
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding-right: 20px;
  padding-left: 80px;
  outline: none;

  box-sizing: border-box;

  border: 1px solid #302711;
  border-radius: 16px;
  background: #fff;
}

.section_two_flex_left_search_body_microchip input::placeholder {
  color: #a89e95;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.section_two_flex_left_search_icon_microchip {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 40px;
  top: 50%;
  /* left: 50%; */
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.section_two_flex_left_search_body_microchip {
  /* width: 50px; */
  height: 80px;
  position: relative;
  width: 50%;
}

.micro_sectionOne_top_flexx_left_search__ {
  display: flex;
  align-items: center;
  gap: 25px;
  margin-top: 90px;
  margin-left: 100px;
}

.section_two_flex_left_search___ {
  background: linear-gradient(
    178.44deg,
    rgba(226, 79, 109, 0.2) 1.32%,
    rgba(226, 79, 109, 0) 126.27%
  );
  display: flex;
  align-items: center;
  gap: 20px;
  border-radius: 16px;
  height: 80px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
}

.micro_sectionOne_top_flexx_left_bigbtn {
  color: #e24f6d;
  font-weight: 700;
  font-size: 22px;
}

.section_two_flex_left_search___:hover
  .micro_sectionOne_top_flexx_left_bigarrow
  img {
  transition: 0.3s ease-in-out 0s;

  transform: translate(5px);
}

.micro_section_catt_imgg {
  position: absolute;
  right: 0;
  bottom: -100px;
  z-index: 10;
}
