.footer_gen_bodyy {
  width: 100%;
  background: #e24f6d;
  .footer_gen_bodyy__sub {
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    gap: 10px;
  }
}

.footer_gen_bodyy__sub ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  //   display: flex;
  //   align-items: center;
}

.footer_gen_bodyy__sub ul li {
  margin-right: 20px;
}

.footer_gen_bodyy__sub ul li a,
.footer_gen_bodyy__sub ul li .link {
  text-decoration: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  position: relative;
  display: inline-block;
  padding: 5px 0;
}

.footer_gen_bodyy__sub ul li a:hover,
.footer_gen_bodyy__sub ul li .link:hover {
  font-weight: 700;
}

/* Underline effect */
.footer_gen_bodyy__sub ul li a::after,
.footer_gen_bodyy__sub ul li .link::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px; /* Thickness of the underline */
  background-color: #fff; /* Color of the underline */
  bottom: 0;
  left: 0;
  transition: width 0.3s ease-in-out;
}

/* Expand underline on hover */
.footer_gen_bodyy__sub ul li a:hover::after,
.footer_gen_bodyy__sub ul li .link:hover::after {
  width: 100%;
}

.footer_gen_bodyy__sub_copyright {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  margin-top: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
}
