.cardWrap {
  display: flex;
  justify-content: space-between;
}
.dashboard-card {
  background-image: url(../../assets/images/home.png);
  width: 32%;
  height: 152px;
  border-radius: 5px;
  padding: 10px 20px 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2 {
    font-weight: 700;
    font-size: 54px;
    color: white;
    width: 40%;
  }

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    color: #ffffff;
    width: 60%;
  }
}

.dashboardMainTable {
  padding: 20px;
  background-color: white;
  margin-top: 30px;
  border-radius: 5px;
  .viewAll {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    button {
      padding: 10px 30px;
      border-radius: 5px;
      background-color: var(--grey2);
      color: white;
      font-size: 12px;
    }
  }
}

.chartTable {
  width: 100%;
  display: flex;
  margin-top: 20px;
  background-color: white;
  height: fit-content;
  padding: 20px;
  height: 470px;
  overflow: hidden;
  justify-content: space-between;
  .col1 {
    width: 49%;
    height: 400px;
    h2 {
      margin-bottom: 30px;
      margin-top: 20px;
      font-size: 20px;
      color: var(--grey2);
      font-weight: 500;
    }
  }

  .col2 {
    width: 49%;
    height: 100%;
    overflow-y: scroll;
    .MTableToolbar-root-5 {
      display: none !important;
    }
    h2 {
      margin-bottom: 30px;
      margin-top: 20px;
      font-size: 20px;
      color: var(--grey2);
      font-weight: 500;
    }
  }
}
