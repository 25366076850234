.main_home_sectionFive_gen_bodyy {
  width: 100%;
  background: #fff;
  position: relative;
  min-height: 1000px;

  .main_home_sectionFive_gen_bodyy__sub {
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 80px;
    padding-bottom: 40px;
    width: 100%;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    box-sizing: border-box;
    gap: 10px;
    position: relative;
    min-height: 1000px;
  }
}

.main_home_sectionFive_gen_bodyy_topGradient {
  background: linear-gradient(
    180deg,
    rgba(239, 195, 86, 0.2) 0%,
    rgba(255, 255, 255, 0.04) 74.55%
  );
  width: 100%;
  height: 140px;
  position: absolute;
  top: 0;
  left: 0;
}

.main_home_sectionFive_gen_bodyfirstFlex {
  width: 100%;
  display: flex;
  gap: 20px;
  box-sizing: border-box;
  align-items: flex-end;
  justify-content: flex-end;
}

.main_home_sectionFive_gen_bodyfirstFlex_left {
  width: calc(100% - 690px);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  flex-direction: column;
}

.main_home_sectionFive_gen_bodyfirstFlex_left_head {
  color: #302711;
  font-size: 32px;
  font-weight: 700;
}

.main_home_sectionFive_gen_bodyfirstFlex_right {
  width: 650px;
  //   height: 670px;
  position: absolute;
  left: 0;
  bottom: 200px;
}

.main_home_sectionFive_gen_bodyfirstFlex_right_img {
  width: 100%;
  height: 100%;
}

.main_home_sectionFive_gen_bodyfirstFlex_right_dog_ {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main_home_sectionFive_gen_bodyfirstFlex_left_body {
  color: #0a0c08;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 30px;
}

.main_home_sectionFive_gen_bodyfirstFlex_left_body span {
  margin-top: 20px;
  display: block;
}

.main_home_sectionFive_gen_bodyfirstFlex_right_cat {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section_five_bottom_img_ppos {
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.section_five_bottom_img_ppos_paw {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.section_five_bottom_img_ppos_dog {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
