.main_home_sectionOne_gen_bodyy {
  width: 100%;
  background: #efc356;
  position: relative;
  min-height: 850px;

  .main_home_sectionOne_gen_bodyy__sub {
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 40px;
    padding-bottom: 40px;
    width: 100%;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    box-sizing: border-box;
    gap: 10px;
    position: relative;
    min-height: 850px;
  }
}

.main_home_sectionOne_gen_sub_textt {
  width: fit-content;
  margin-left: auto;
  margin-right: 160px;
  color: #302711;
  font-weight: 700;

  font-size: 48px;
  margin-top: 20px;
  text-align: center;
}

.main_home_sectionOne_gen_sub_hold {
  position: absolute;
  left: 0;
  bottom: -4px;
}

.main_home_sectionOne_gen_sub_rope {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main_home_sectionOne_gen_sub_dog_gradient_b {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main_home_sectionOne_gen_gradient {
  position: absolute;
  right: 0;
  bottom: 0;
}

.main_home_sectionOne_gen_gradient_dog {
  position: absolute;
  right: 0;
  bottom: -4px;
}

.main_home_sectionOne_gen_reg_btn {
  position: absolute;
  top: 75%;
  left: 65%;
  transform: translate(-50%, -50%);
  z-index: 1;
  background: #302711;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 18px 20px;
  border-radius: 40px;
  cursor: pointer;
  transition: 0.3s ease-in-out 0s;
}

.main_home_sectionOne_gen_reg_btn_text {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  transition: 0.3s ease-in-out 0s;
}

.main_home_sectionOne_gen_reg_btn_arrowright {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-in-out 0s;
}

.main_home_sectionOne_gen_reg_btn:hover
  .main_home_sectionOne_gen_reg_btn_arrowright
  img {
  transition: 0.3s ease-in-out 0s;

  transform: translate(5px);
}
