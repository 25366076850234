.gradient_two_flex_left_search___ {
  background: linear-gradient(
    178.44deg,
    rgba(226, 79, 109, 0.2) 1.32%,
    rgba(226, 79, 109, 0) 126.27%
  );
  display: flex;
  align-items: center;
  gap: 20px;
  border-radius: 16px;
  height: 80px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  width: fit-content;
}

.gradient_sectionOne_top_flexx_left_bigbtn {
  color: #e24f6d;
  font-weight: 700;
  font-size: 22px;
}

.gradient_sectionOne_top_flexx_left_bigarrow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gradient_two_flex_left_search___:hover
  .gradient_sectionOne_top_flexx_left_bigarrow
  img {
  transition: 0.3s ease-in-out 0s;

  transform: translate(5px);
}
