.successModal {
  width: 450px;
  // padding: 30px;
  h2 {
    font-size: 25px;
    margin-bottom: 16px;
    margin-top: 30px;
    font-weight: 500;
  }

  .mdInputWrap {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    input {
      width: 100%;
      height: 45px;
      border: none;
      border: 1px solid #bfbfbf;
      background-color: transparent;
      margin-top: 10px;
      padding-left: 10px;
      border-radius: 5px;
    }
  }

  .sendRequestBtn {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 40px;
    button {
      background-color: var(--grey2);
      padding: 14px 30px;
      border-radius: 5px;
      color: white;
    }
  }
}
