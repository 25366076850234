.missing_pets_dog_absolutttt {
  position: absolute;
  left: 50%;
  bottom: -5px;
  transform: translateX(-50%);
}

.missing_pets_dog_absolutttt_gradient_body {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
}
