.main_microchipping_body_imageee_gen {
  background-image: url(../../../../assets/images/map_jpg.png);
  display: flex;
  flex-direction: row;
  background-size: cover;
  background-position: center;
  //   padding: 20px;
  height: 100vh;
  overflow-y: scroll;
  //   overflow-x: hidden;
}

.main_microchipping_body_imageee_gen_two {
  width: 40%;
  box-sizing: border-box;
}

.micro_sectionOne_top_flexx_left_search____ {
  display: flex;
  align-items: center;
  //   gap: 25px;
  //   margin-top: 90px;
  //   margin-left: 100px;
  width: 100%;
  box-sizing: border-box;
  height: fit-content;
  margin-bottom: 20px;
}

.section_two_flex_left_search_body_microchip_two input {
  color: #2d3748;
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding-right: 20px;
  padding-left: 80px;
  outline: none;

  box-sizing: border-box;

  border: 1px solid #302711;
  border-radius: 16px 0px 0px 16px;
  background: #fff;
}

.section_two_flex_left_search_body_microchip_two input::placeholder {
  color: #a89e95;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.section_two_flex_left_search_icon_microchip_two {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 40px;
  top: 50%;
  /* left: 50%; */
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.section_two_flex_left_search_body_microchip_two {
  width: calc(100% - 100px);
  height: 80px;
  position: relative;
  box-sizing: border-box;
}

.section_two_flex_left_search_icon_ext {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 100px;
  background: #e24f6d;
  border-radius: 0px 16px 16px 0px;
  cursor: pointer;
}

.map_microchipping_head_find {
  color: #302711;
  font-weight: 700;
  font-size: 45px;
  text-align: left;
  margin-bottom: 20px;
  margin-top: 130px;
}

.map_microchipping_head_list {
  color: #302711;
  font-weight: 400;
  font-size: 18px;
  text-align: left;
  margin-bottom: 50px;
  margin-top: 30px;
}
