.appInput {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  label {
    color: #302711;
    font-size: 15px;
    margin-bottom: 6px;
  }
  .iconRight {
    position: absolute;
    top: 12px;
    right: 10px;
    svg {
      color: #a89e95;
      font-size: 18px;
      cursor: pointer;
    }
  }

  .iconLeft {
    position: absolute;
    top: 12px;
    left: 10px;
    svg {
      color: #a89e95;
      font-size: 18px;
      cursor: pointer;
    }
  }
  input {
    width: 100%;
    height: 40px;
    border: 1px solid #efc356;
    background-color: #fff;
    color: #a89e95;
    font-size: 17px;
    outline: none;
  }
  select {
    width: 100%;
    height: 40px;
    border: 1px solid #efc356;
    background-color: #fff;
    color: #a89e95;
    font-size: 17px;
    outline: none;
  }
}

.appInputFileWrap {
  display: flex;
  flex-direction: column;
  label {
    color: #302711;
    font-size: 15px;
    margin-bottom: 6px;
  }
  .appInputFile {
    width: 100%;
    height: 140px;
    border: 1px dotted #efc356;
    background-color: #fff;
    color: #a89e95;
    font-size: 17px;
    outline: none;
    border-radius: 5px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      width: 100%;
      height: 100%;
      position: absolute;
      outline: none;
      border: none !important;
      opacity: 0;
      cursor: pointer;
    }

    .appInputFileDes {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      img {
        width: 50px;
      }
      h2 {
        color: #302711;
        font-size: 13px;
        margin-bottom: 5px;
        margin-top: 10px;
      }
      p {
        font-size: 11px;
      }
    }
  }
}
