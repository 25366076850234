.pets-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.title {
  font-size: 2rem;
  margin-bottom: 20px;
}

.grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  //   max-width: 1200px;
}

.pet-card {
  background-color: #fff;
  //   padding: 15px;
  //   border-radius: 10px;
  //   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 350px;
  margin-bottom: 100px;
}

.pet-card-h3 {
  color: #302711;
  margin-top: 50px;
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: 400;
}

.pet-image {
  width: 318px;
  height: 325px;
  //   object-fit: contain;
  //   border-radius: 10px;
  margin-bottom: 10px;
}

.pet-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.details-button {
  background-color: #ef476f;
  color: white;
  border: none;
  padding: 25px;
  border-radius: 12px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  margin-left: auto;
  margin-right: auto;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}

// .pagination button {
//   background-color: #f1f1f1;
//   border: none;
//   margin: 0 5px;
//   padding: 10px 15px;
//   cursor: pointer;
// }

.pagination button.active {
  background-color: #e24f6d;
  border: 1px solid #e24f6d;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  padding: 10px 15px;
}

.pagination button.none_active_pagination_btn {
  background-color: #fff;
  border: 1px solid #e24f6d;
  color: #e24f6d;
  font-size: 18px;
  font-weight: 700;
  padding: 10px 15px;
}

.pagination button:disabled {
  cursor: not-allowed;
  //   background-color: #fff;

  opacity: 0.5;
}

.pagination_chev_left {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
}

.pagination_chev_right {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
}
