.auth {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: var(--base-color);
  .inputWrapauth {
    width: 500px;
    height: fit-content !important;
    padding-bottom: 50px;
    background-color: white;
    border-radius: 5px;
    filter: drop-shadow(1px 1px 1px rgba(128, 128, 128, 0.205));
    padding-left: 40px;
    padding-right: 40px;
    .logoAuth {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding-top: 35px;
      img {
        width: 200px;
      }
    }

    form {
      width: 100%;
      margin-top: 40px;
      .inputWrapHo {
        width: 100%;
        margin-bottom: 20px;
        label {
          font-size: 17px;
          color: #5c5c5c;
        }
        input {
          width: 100%;
          height: 40px;
          margin-top: 6px;
          border: 1px solid #5c5c5c;
          border-radius: 4px;
          padding-left: 10px;
        }
      }
    }

    .btnAuth {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      button {
        height: 40px;
        width: 100%;
        background: #4169e2;
        border-radius: 5px;
        color: white;
      }
    }
  }
}

.petOwnerDesCon {
  text-align: center;
  line-height: 26px;
  p {
    font-size: 15px;
    color: #302711;
  }

  span {
    color: #973549;
    font-size: 15px;
    cursor: pointer;
    text-decoration: underline;
    // font-style: italic;
  }
}

.forgotPass {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 5px;
  p {
    font-size: 13px;
    color: #302711;
  }

  span {
    color: #973549;
    font-size: 13px;
    cursor: pointer;
    text-decoration: underline;
    // font-style: italic;
  }
}

.submitform {
  width: 100%;

  button {
    width: 100%;
    color: #302711;
    font-size: 17px;
  }
}

.authWrap-create-acc {
  background-color: #efc356;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
  .header {
    .logo {
      padding-top: 30px;
      padding-left: 30px;
      img {
        height: 100px;
      }
    }
  }
  .mainForm {
    height: calc(100vh - 120px);
    overflow-y: scroll;
    .header-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 30px;

      h2 {
        color: #302711;
        font-weight: 700;
        font-size: 30px;
      }
      p {
        color: #302711;
        font-size: 19px;
        margin-top: 10px;
      }
    }
    .petForm {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 25px;

      .petFormWrap {
        background-color: #fcf3dd;
        width: 700px;
        padding: 30px;
        border-radius: 5px;
        margin-bottom: 50px;
      }
    }
  }
  .petImageBottom {
    position: absolute;
    bottom: 0;
    left: 0;
    img {
      height: 350px;
    }
  }
}

.petOwner {
  .petOwnerDes {
    .dimacation {
      width: 100%;
      height: 1px;
      background-color: #e24f6d;
      margin-top: 20px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .dimacation::before {
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #e24f6d;
    }
    .dimacation::after {
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #e24f6d;
    }
  }
}

// 1553
@media (max-width: 1553px) {
  .authWrap-create-acc {
    .petImageBottom {
      img {
        height: 250px;
      }
    }
  }
}

@media (max-width: 1304px) {
  .authWrap-create-acc {
    .petImageBottom {
      img {
        height: 150px;
      }
    }
  }
}

@media (max-width: 997px) {
  .authWrap-create-acc {
    .petImageBottom {
      display: none;
    }
    .mainForm {
      .petForm {
        .petFormWrap {
          width: 90vw;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .authWrap-create-acc {
    .petImageBottom {
      display: none;
    }
    .mainForm {
      .header-text {
        p {
          font-size: 12px;
        }
      }
      .petForm {
        .petFormWrap {
          width: 90vw;
        }
      }
    }
  }
}
