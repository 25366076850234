.Vaccination_details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;
  div:first-child {
    p {
      color: rgba(128, 128, 128, 0.857);
      font-size: 16px;
    }
  }

  div:last-child {
    p {
      font-size: 16px;
    }
  }
}

.notification-page {
  .noti-card {
    background-color: #fff;
    width: 700px;
    height: 100px;
    margin-bottom: 7px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    border: 1px solid #efc456c6;
    .noti-cardCol1 {
      margin-right: 20px;
      svg {
        color: #302711;
      }
    }
    .noti-cardCol2 {
      h2 {
        color: #302711;
        font-size: 17px;
        margin-bottom: 7px;
      }
      p {
        color: #a89e95;
        font-size: 13px;
        span {
          color: #e24f6d;
        }
      }
    }
  }
}

.headerText {
  margin-bottom: 20px;
  h2 {
    color: #302711;
    font-size: 30px;
  }
}

.contactUsPageCard {
  background-color: #fff;
  width: 700px;
  height: 100px;
  margin-bottom: 7px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #efc456c6;
  .contactUsPageCardCol1 {
    display: flex;
    flex-direction: column;
    p {
      // text-align: center;
      font-size: 19px;
      margin-bottom: 10px;
      span {
        margin-right: 10px;
        svg {
          color: #efc356;
          width: 20px;
        }
      }
    }
    h2 {
      font-size: 15px;
      margin-left: 30px;
    }
    // align-items: center;
  }

  .contactUsPageCardCol2 {
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      text-align: center;
      font-size: 18px;
    }
    svg {
      cursor: pointer;
      color: #efc356;
      font-size: 22px;
    }
  }
}

.annualDuePageWrap {
  .annualDuesPage {
    background-color: #fff;
    width: 500px;
    // height: 100px;
    margin-bottom: 7px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    border: 1px solid #efc456c6;
    padding-top: 20px;
    padding-bottom: 20px;
    .annualDuesPageCol1 {
      svg {
        color: #efc356;
        font-size: 30px;
      }
    }
    .annualDuesPageCol2 {
      margin-left: 20px;
      h2 {
        color: #011602;
        font-size: 20px;
      }
      h3 {
        color: #011602;
        font-size: 20px;
        margin-bottom: 10px;
      }
      p {
        color: #a89e95;
        font-size: 18px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }

  .warningAnn {
    margin-top: 30px;
    p {
      font-size: 17px;
      color: #973549;
    }
  }
}

.reportMissingPetCard {
  p {
    line-height: 30px;
    color: #302711;
    font-size: 17px;
  }
  button {
    background-color: #973549;
    padding-right: 20px;
    padding-left: 20px;
    color: #fff;
    margin-top: 20px;
    font-size: 15px;
  }
}

.invoiceList737 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  // margin-bottom: 5px;
  .invoiceListCol1 {
    h2 {
      font-size: 16px;
      color: #3027119a;
    }
  }

  .invoiceListCol2 {
    p {
      font-size: 16px;
      color: #302711;
    }
  }
}
