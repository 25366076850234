.shipmentHead {
}

.summaryBody {
  width: 100%;
  height: fit-content;
  background-color: white;
  margin-top: 20px;
  border-radius: 5px;
  border: 1px solid #5c5c5c85;
  display: flex;
  .col1 {
    .usersumship {
      font-weight: 500;
      font-size: 20px;
      margin-bottom: 10px;
    }
    padding: 20px;
    width: 50%;
    .timerSvg {
      display: flex;
      align-items: center;
      margin-top: 10px;
      font-size: 12px;
      svg {
        margin-right: 7px;
      }
    }

    .fromwrap {
      width: 100%;
      // height: 70px;
      border: 1px solid #5c5c5c59;
      margin-top: 20px;
      padding: 20px;
      border-radius: 5px;
      div {
        margin-bottom: 15px;
      }
      h2 {
        font-weight: 300;
        font-size: 15px;
      }
      p {
        color: #5c5c5cf6;
        font-size: 14px;
        margin-top: 10px;
      }
    }
  }
  .col2 {
    padding-top: 20px;
    button {
      margin-right: 10px;
    }
    .header {
      margin-bottom: 20px;
    }
  }
}
