.map_component_gen_bodyy_secmicro {
  display: flex;
  align-items: flex-start;
  gap: 25px;
  flex-direction: column;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  background: #fcf3dd;
  border: 0.5px solid #fcf3dd;
  padding: 40px;
  margin-bottom: 20px;
}

.map_component_gen_bodyy_secmicro_head {
  color: #0a0c08;
  font-size: 22px;
  font-weight: 700;
  text-align: left;
}

.map_component_gen_bodyy_secmicro_subb {
  color: #0a0c08;
  font-size: 18px;
  font-weight: 400;
}

.map_component_gen_bodyy_secmicro_num {
  color: #0a0c08;
  font-size: 18px;
  font-weight: 400;
}

.map_component_gen_bodyy_secmicro_btn {
  background: #e24f6d;
  border-radius: 12px;
  padding: 20px;
  color: #ffffff;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
}
