.deleteHeader {
  font-weight: 600;
  font-size: 15px;

  color: #ff0000;
}

.deleteSubText {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 13px;
  color: #000000;
}
.deleteYesBtn {
  width: 100%;
  height: 49px;

  background: #4169e2;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;

  color: #ffffff;
  margin-top: 10px;
  cursor: pointer;
  transition: 0.3s ease-in-out 0s;
}

.deleteYesBtn:hover {
  background: #3153b8;
}
.deleteNoBtn {
  width: 100%;
  height: 49px;

  background: transparent;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #000000;
  font-weight: 400;
  font-size: 14px;

  color: #000000;
  margin-top: 10px;
  cursor: pointer;
  transition: 0.3s ease-in-out 0s;
}

.deleteNoBtn:hover {
  background: #efeeee;
}
