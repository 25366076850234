.blog_post_card_blog_sectionGen {
  height: 800px;
  width: 600px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  flex-direction: column;
}

.blog_post_card_blog_sectionGen_img {
  width: 600px;
  height: 548px;
  box-sizing: border-box;
  overflow: hidden;
}

.blog_post_card_blog_sectionGen_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog_post_card_blog_sectionGen_head {
  color: #181b24;
  font-size: 22px;
  font-weight: 700;
  margin-top: 20px;
}

.blog_post_card_blog_sectionGen_body {
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 400;
  color: #181b24;
}

.blog_gradient_two_flex_left_search___ {
  background: linear-gradient(
    178.44deg,
    rgba(226, 79, 109, 0.2) 1.32%,
    rgba(226, 79, 109, 0) 126.27%
  );
  display: flex;
  align-items: center;
  gap: 20px;
  border-radius: 16px;
  height: 60px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  width: fit-content;
}

.blog_gradient_sectionOne_top_flexx_left_bigbtn {
  color: #e24f6d;
  font-weight: 700;
  font-size: 18px;
}

.blog_gradient_sectionOne_top_flexx_left_bigarrow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog_gradient_two_flex_left_search___:hover
  .blog_gradient_sectionOne_top_flexx_left_bigarrow
  img {
  transition: 0.3s ease-in-out 0s;

  transform: translate(5px);
}
