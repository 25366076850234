.missing_pet_head_section_twoooo {
  color: #302711;
  font-weight: 700;
  font-size: 45px;
  text-align: left;
  margin-bottom: 50px;
  margin-top: 150px;
}

.missing_pet_body_section_twoooo {
  color: #000000;
  font-size: 18px;
  font-weight: 400;
}
.missing_pet_body_section_twoooo span {
  margin-top: 20px;
  display: block;
  width: 80%;
}

.missing_pet_body_section_twoooo_btnnn {
  margin-top: 90px;
}

.missing_pet_body_section_______gr {
  position: absolute;
  right: 0;
  bottom: -4px;
}
