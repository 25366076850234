.TablePagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  .count {
    display: flex;
    background-color: #4169e290;
    margin-right: 10px;
    margin-left: 10px;
    padding: 7px 20px;
    border-radius: 3px;
    p {
      color: white;
      font-size: 12px;
    }
  }

  .previous {
    padding: 10px;
    p {
      cursor: pointer;
    }
  }

  .next {
    padding: 10px;
    p {
      cursor: pointer;
    }
  }
}
