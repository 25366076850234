.contact_ussupport-container {
  border: 0.5px solid #e24f6d;
  width: 70%;
  padding: 60px;
  box-sizing: border-box;
  box-shadow: 4px 8px 12px 0px rgba(66, 23, 31, 0.2509803922);
  min-height: 700px;
  border-radius: 12px;
}

.contact_ussupport_header_su {
  color: #e24f6d;
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 20px;
}

.contact_ussupport_header_su_bodyy {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 40px;
}

.contact_ussupport_header_su_bodyy_icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact_ussupport_header_su_bodyy_text {
  color: #0a0c08;
  font-size: 18px;
  font-weight: 400;
}

.contact_ussupport_header_su_bodyy_text span {
  display: block;
}
