.profileImageWrap {
  width: 120px;
  height: 120px;
  display: flex;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid #efc356;
  .cameraIconWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #efc356;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding: 10px;
    position: absolute;
    bottom: 9px;
    right: 7px;
    z-index: 3;
    svg {
      color: #fff;
      width: 13px;
    }
  }
  input {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    z-index: 5;
    cursor: pointer;
  }
}
