.main_home_sectionOne_gen_bodyy__subH_text {
  color: #302711;
  font-size: 34px;
  font-weight: 700;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  text-align: center;
}

.main_home_sectionOne_gen_bodyy__subH_flex {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-top: 75px;
  flex-wrap: wrap;
}

.main_home_sectionThree_gen_bodyy {
  width: 100%;
  background: #fff;
  position: relative;
  min-height: 850px;

  .main_home_sectionThree_gen_bodyy__sub {
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 80px;
    padding-bottom: 40px;
    width: 100%;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    box-sizing: border-box;
    gap: 10px;
    position: relative;
    min-height: 850px;
  }
}

.main_home_sectionOne_gen_bodyy__subH_rope {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}

.main_home_sectionOne_gen_bodyy__substart_hebtnFam {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  margin-top: 70px;
  margin-bottom: 220px;
}

.main_home_sectionOne_gen_bodyy__substart_hebtn {
  background: #e24f6d;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: 10px;
  padding: 18px 20px;
  border-radius: 40px;
  cursor: pointer;
  transition: 0.3s ease-in-out 0s;
}

.main_home_sectionOne_gen_bodyy__substart_hetext {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
}

.main_home_sectionOne_gen_bodyy__substart_hearrow {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.main_home_sectionOne_gen_bodyy__substart_hebtn:hover
  .main_home_sectionOne_gen_bodyy__substart_hearrow {
  transform: translate(5px);
  transition: 0.3s ease-in-out 0s;
}

.main_home_sectionOne_gen_bodyy__substart_dogggg {
  position: absolute;
  left: 150px;
  bottom: -2px;
}
