.accordion-container {
  border: 0.5px solid #e24f6d;
  width: 70%;
  padding: 60px;
  box-sizing: border-box;
  box-shadow: 4px 8px 12px 0px rgba(66, 23, 31, 0.2509803922);
  min-height: 700px;
  border-radius: 12px;
}

.accordion-item {
  border-bottom: 1px solid #efc356;
  padding: 5px 0;
  margin-bottom: 10px;
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  color: #333;
}

.support_faq_header__accordion {
  color: #0a0c08;
  font-size: 18px;
  font-weight: 400;
}

.accordion-header h3 {
  margin: 0;
}

.accordion-content {
  margin-top: 10px;
  padding: 20px;
  background-color: #fcf3dd80;
  //   border-left: 4px solid #f1c40f;
  //   border-radius: 5px;
  color: #0a0c08;
  font-size: 18px;
  font-weight: 400;
  box-sizing: border-box;
}

.expand-icon {
  font-size: 20px;
  color: #f1c40f;
  cursor: pointer;
}
