.layout {
  display: flex;
  height: 100vh;
  overflow: hidden;
  justify-content: space-between;
  .layout__sidebar {
    width: 250px;
    height: 100%;
    background-color: #efc356;
    // padding-right: 10px;/
    .sidebar {
      .appLogo {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 135px;
        }
      }

      .loginUserProfileDetails {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .avatar {
          width: 80px;
          height: 80px;
          background-color: #fcf3dd;
          border: 1px solid #e24f6d;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          border-radius: 50%;
          padding: 20px;
        }
        h2 {
          color: #302711;
          font-size: 13px;
          font-weight: 400;
          margin-top: 7px;
        }

        p {
          color: #302711;
          font-size: 11px;
          font-weight: 400;
          margin-top: 3px;
        }
      }
    }

    .mainListWrap {
      display: flex;
      flex-direction: column;
      margin: 0;
      margin-top: 30px;

      li {
        margin: 0;
        margin-bottom: 10px;
        padding-left: 20px;
        display: flex;
        align-items: center;
        padding-top: 15px;
        padding-bottom: 15px;
        cursor: pointer;
        transition: background-color 400ms ease;
        span {
          font-weight: 400;
          color: #302711;
          font-size: 15px;
          margin-left: 6px;
        }
        svg {
          color: #302711;
          width: 20px;
        }
      }
      li:hover {
        background-color: #f4d78e;
      }

      .active {
        background-color: #f4d78e;
      }
    }
  }
  .children-wrap {
    width: calc(100% - 250px);
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    overflow: hidden;
    overflow-y: scroll;
    background: #fcf3dd;
  }
}
